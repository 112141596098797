import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { Theme } from "../../config/aws-exports";
import { CircularProgress, IconButton, Link } from "@mui/material";
import { Close } from "@mui/icons-material";

import config from "../../config/aws-exports";
import logo from "../../assets/USP logo R.png";
import usp from "../../assets/USP.png";
import "../../components/Styles/clearfix.css";

import signature from "../../assets/signature.png";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import "./certificate.css";
import moment from "moment";
import Pdf from "react-to-pdf";
import { API } from "aws-amplify";
import { Box } from "@mui/system";
import CourseFeedback from "../Course/CourseFeedback";
export default function CertificatePopUp({ setOpen, open, sData, setIsLoading, isLoading, refetch }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const userDetails = useSelector(authData);
  const content = React.useRef();
  const certificateDiv = React.useRef();
  const [name, setName] = React.useState("");
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  console.log(sData);
  React.useEffect(() => {
    const getUserDetails = async () => {
      setLoading(true);
      const bodyParam = {
        body: {
          emailid: userDetails.email,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/getUserDetails",
          bodyParam
        );

        if (response) {
          const res = response.body[0];
          const name = res.first_name + " " + res.last_name;
          setName(name);
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getUserDetails();
  }, [userDetails.email]);
  const handleClickFeedbackOpen = () => {

    setFeedbackOpen(true);
  };
  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-title" className="clearfix">

          {sData?.sn == undefined ? sData?.tn : sData?.sn}



          <IconButton
            onClick={() => {
              // setFeedbackOpen(true);
              handleClose();
            }}
            sx={{ position: "absolute", right: 10, top: 10, marginLeft: '200px' }}
          >
            <Close sx={{ fontSize: "2rem" }} />
          </IconButton>

        </DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '97%' }}>
          <div style={{ textAlign: 'left', marginLeft:'25px' }}>
            <p>Please fill the feedback before downloading the certificate</p>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginBottom: '4px' }}>
              <Link
                variant="outlined"
                style={{
                  color: Theme.color.primary,
                  borderColor: Theme.color.primary,
                  margin: '10px',
                  border: '1px solid #FF6F4D',
                  padding: '9px 15px',
                  textDecoration: 'none',
                  borderRadius: '4px',
                  fontSize: '0.875rem',
                  fontWeight: '500',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
                }}
                href={`https://usp.az1.qualtrics.com/jfe/form/SV_aeEj4dGn2w9OoJw?ProgramName=${sData?.sn == undefined ? sData?.tn : sData?.sn}&EmailAddress=${userDetails?.uData.emailid}
&OrgName=${userDetails?.uData.oid}&sessID=${sData?.tid == undefined ? sData?.sid : sData?.tid}&userID=${userDetails?.uData.ur_id}&SessionType='onDemand'&Session_Date=${sData.con}&ProgramID=${sData?.tid == undefined ? sData?.sid : sData?.tid}`}
                target="_blank"
              >
                Feedback
              </Link>
            </div>
            <div>
              <Pdf
                targetRef={content}
                filename={
                  sData?.sn?.length >= 90 ? sData?.sn?.substring(0, 90) : sData?.sn
                }
              >
                {({ toPdf }) => (
                  <Button
                    sx={{
                      color: Theme.color.primary,
                      borderColor: Theme.color.primary,
                      textTransform: "unset",
                      "&:hover": {
                        color: Theme.color.primary,
                        borderColor: Theme.color.primary,
                        textTransform: "unset",
                      },
                      marginBottom: '5px',
                    }}
                    variant="outlined"
                    onClick={toPdf}
                  >
                    Download
                  </Button>
                )}
              </Pdf>
            </div>
          </div>
        </div>
        <DialogContent ref={content} className="overall-container" >
          {loading ? (
            <Box
              sx={{
                height: "700px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={{ height: "50vh" }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <div className="container" ref={certificateDiv}>
                <div className="img-c">
                  <img src={logo} alt="logo" height="100" />
                </div>
                <div className="org-container">
                  <div class="img-c">
                    <img src={usp} alt="USP" height="50" width="auto" />
                  </div>
                  <p>Certificate awarded to</p>
                </div>
                <h1 className="name">{name}</h1>
                <p className="complete">for successfully completing</p>
                <div className="title-container">
                  <h2 className="title">{sData?.sn || sData?.tn}</h2>
                  <h2 className="title">
                    {sData?.stype === 1 && "(Live Webinar)"}
                    {sData?.stype === 2 && "(Classroom)"}
                    {sData?.stype === 3 && "(Hybrid)"}
                    {sData?.stype === undefined && "(On Demand Course)"}
                  </h2>
                </div>
                <p className="date">
                  {/* {sData?.date_list
                    ? moment(new Date(sData?.date_list[0]?.date).combineStartTime).format(
                        "DD MMM YYYY"
                      )
                    : moment(new Date(sData?.startdate)).format("DD MMM YYYY")} */}
                  Date : &nbsp;
                  {sData?.date_list &&
                    sData?.date_list[0]?.combineStartTime &&
                    moment(sData?.date_list[0]?.combineStartTime).format(
                      "DD MMM YYYY"
                    )}
                  {sData?.stype === undefined && sData?.comp_date &&
                    moment(sData?.comp_date).format(
                      "DD MMM YYYY"
                    )}
                  {/* {sData?.date_list[0]?.combineStartTime &&
                    moment(sData?.date_list[0]?.combineStartTime).format(
                      "DD MMM YYYY"
                    )}
                  {sData?.date_list &&
                    sData?.date_list?.length > 1 &&
                    sData?.date_list?.[0]?.combineEndTime &&
                    " - " +
                      moment(
                        sData?.date_list?.[sData?.date_list?.length - 1]
                          ?.combineEndTime
                      ).format("DD MMM YYYY")} */}
                </p>
                <p>
                  {sData?.location_value == null ||
                    sData?.location_value === "null" ||
                    sData?.location_value === undefined
                    ? ""
                    : sData?.location_value}
                </p>

                <div className="img-c">
                  <img src={signature} alt="signature" height="130" />
                </div>
              </div>
            </>
          )}
        </DialogContent>

      </Dialog>
      {feedbackOpen && (
        <Dialog
          open={feedbackOpen}
          aria-labelledby="responsive-dialog-title"
          BackdropProps={{
            sx: {
              backgroundColor: Theme.backDropOpacity,
            },
          }}
          scroll="paper"
          maxWidth="lg"
        >
          <CourseFeedback
            handleFeedbackClose={handleFeedbackClose}
            sid={sData?.tid}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            type="course"
            ur_id={userDetails?.uData.ur_id}
            handleClose={handleClose}
            refetch={refetch}
          />
        </Dialog>
      )}
    </div>
  );
}
