import { createGlobalStyle } from "styled-components";
export const Theme = {
  color: {
    primary: "#FF6F4D",
    secondary: "#275780",
    appGray: "#7c7f91",
    secondaryLight: "rgba(40, 87, 129, 0.2)",
    grey_light_1: "#f7f7f7",
    grey_light_2: "#eee",

    grey_dark: "#777",
    grey_dark_2: "#999",
    grey_dark_3: "#333",

    white: "#fff",
    black: "#000",
  },
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  backDropOpacity: "rgba(0, 0, 0, 0.85)",
};

export const GlobalStyle = createGlobalStyle`
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow-x: hidden;
  font-family: "graphic",sans-serif;
  
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}



/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.swal2-container {
  z-index: 100000;
}
.MuiSnackbar-root{
  z-index: 100000;
}
body{
  overflow-y: visible !important;
}
`;

export const constants = {};

const uspdev = {
  //aws_app_analytics: 'disable',
  razorPayId: "rzp_test_Hf6C5b56ZkAYTR",
  tenant: "TenantOne",
  stid: 22,
  main_color_1: "#0f80a4",
  main_color_2: "#f18121",
  schema: "uspsm",
  platform_main_theme: "linear-gradient(#0f80a4, #0f80a4)",
  button_color_web: "#0f80a4",
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "82c27w380i",
      name: "USP-PLATFORM",
      description: "",
      endpoint: "https://82c27w380i.execute-api.us-east-1.amazonaws.com/US8454",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "82c27w380i",
      name: "USP-PLATFORM",
      description: "",
      endpoint: "https://82c27w380i.execute-api.us-east-1.amazonaws.com/US8454",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "USP-PLATFORM",
  aws_cloud_logic_custom_name_E: "USP-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:46d24045-4731-4036-813e-399b36a8b1e0",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-dev-usp",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1k5zdr4y9ks9p.cloudfront.net",
  DOMAIN: "d1k5zdr4y9ks9p.cloudfront.net",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  //aws_mobile_analytics_app_id: 'f085468f71664440ba6153ccb1036a9a',
  //aws_mobile_analytics_app_region: 'us-east-1',
  //aws_project_id: '5b55d3ef-e764-4657-b5c6-c0b023e8790e',
  //aws_project_name: 'EnhanzedRN',
  aws_project_region: "us-east-1",
  //aws_push_apns: 'enable',
  //aws_push_pinpoint: 'enable',
  aws_resource_name_prefix: "usp-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_yY0XFPPC7",
  aws_user_pools_web_client_id: "17onmq64lhscop8aofuh9spcof",
  aws_cloud_logic_custom_endpoint:
    "https://82c27w380i.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "USP",
  aws_org_id_E: "USP",
  aws_user_pools_id_E: "us-east-1_yY0XFPPC7",
  aws_user_pools_web_client_id_E: "1pd107l82ghrr0a8ptmgi9njlg",
  //aws_mobile_analytics_app_id_E: 'f085468f71664440ba6153ccb1036a9a',
  aws_content_delivery_cloudfront_domain_E: "d1if2pjtoq2sst.cloudfront.net",
  aws_cloudfront_url: "d1k5zdr4y9ks9p.cloudfront.net",
  aws_cognito_identity_pool_id_E:
    "us-east-1:55a63979-c796-4fc3-8fc8-140fae5debf6",
  aws_cloud_logic_custom_endpoint_E:
    "https://82c27w380i.execute-api.us-east-1.amazonaws.com/US8454",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "82c27w380i",
      name: "USP-PLATFORM",
      description: "",
      endpoint: "https://82c27w380i.execute-api.us-east-1.amazonaws.com/US8454",
      region: "us-east-1",
    },
  ],
  // oauth: {
  //   domain: 'enhanzedprod.auth.us-east-1.amazoncognito.com',
  //   scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  //   redirectSignIn: 'enhanzed://',
  //   redirectSignOut: 'enhanzed://',
  //   responseType: 'code',
  // },
};
const usppreprod = {
  //aws_app_analytics: 'disable',
  razorPayId: "rzp_test_Hf6C5b56ZkAYTR",
  tenant: "TenantOne",
  stid: 22,
  main_color_1: "#0f80a4",
  main_color_2: "#f18121",
  schema: "uspsm",
  platform_main_theme: "linear-gradient(#0f80a4, #0f80a4)",
  button_color_web: "#0f80a4",
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "dd4c9icls3",
      name: "USP-PLATFORM",
      description: "",
      endpoint:
        "https://dd4c9icls3.execute-api.us-east-1.amazonaws.com/PROD73564",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "dd4c9icls3",
      name: "USP-PLATFORM",
      description: "",
      endpoint:
        "https://dd4c9icls3.execute-api.us-east-1.amazonaws.com/PROD73564",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "USP-PLATFORM",
  aws_cloud_logic_custom_name_E: "USP-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:46d24045-4731-4036-813e-399b36a8b1e0",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-p2-usp",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "www.p2.usp.enhanzed.com",
  DOMAIN: "www.p2.usp.enhanzed.com",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",

  aws_project_region: "us-east-1",
  aws_resource_name_prefix: "usp-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_GCbbYM0y3",
  aws_user_pools_web_client_id: "1he1mvsib64em3d16cqhkv0ibd",
  aws_cloud_logic_custom_endpoint:
    "https://dd4c9icls3.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "USP",
  aws_org_id_E: "USP",
  aws_user_pools_id_E: "us-east-1_GCbbYM0y3",
  aws_user_pools_web_client_id_E: "1he1mvsib64em3d16cqhkv0ibd",
  //aws_mobile_analytics_app_id_E: 'f085468f71664440ba6153ccb1036a9a',
  aws_content_delivery_cloudfront_domain_E: "www.p2.usp.enhanzed.com",
  aws_cloudfront_url: "www.p2.usp.enhanzed.com",
  aws_cognito_identity_pool_id_E:
    "us-east-1:55a63979-c796-4fc3-8fc8-140fae5debf6",
  aws_cloud_logic_custom_endpoint_E:
    "https://dd4c9icls3.execute-api.us-east-1.amazonaws.com/PROD73564",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "dd4c9icls3",
      name: "USP-PLATFORM",
      description: "",
      endpoint:
        "https://dd4c9icls3.execute-api.us-east-1.amazonaws.com/PROD73564",
      region: "us-east-1",
    },
  ],
};
const uspprod = {
  //aws_app_analytics: 'disable',
  razorPayId: "rzp_live_3qEgOLz0KgJRs5",
  tenant: "TenantOne",
  stid: 6,
  main_color_1: "#0f80a4",
  main_color_2: "#f18121",
  schema: "uspsm",
  platform_main_theme: "linear-gradient(#0f80a4, #0f80a4)",
  button_color_web: "#0f80a4",
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "j6k3abvofd",
      name: "USP-PLATFORM",
      description: "",
      endpoint:
        "https://j6k3abvofd.execute-api.ap-south-1.amazonaws.com/USP01prod",
      region: "ap-south-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "j6k3abvofd",
      name: "USP-PLATFORM",
      description: "",
      endpoint:
        "https://j6k3abvofd.execute-api.ap-south-1.amazonaws.com/USP01prod",
      region: "ap-south-1",
    },
  ],
  aws_cloud_logic_custom_name: "USP-PLATFORM",
  aws_cloud_logic_custom_name_E: "USP-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:f6c1c940-5cc0-4779-9813-d1f4ca08f60a",
  aws_cognito_region: "ap-south-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-prod-usp",
  aws_content_delivery_bucket_region: "ap-south-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1gr06hnj0sjrr.cloudfront.net",
  DOMAIN: "d1gr06hnj0sjrr.cloudfront.net",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  //aws_mobile_analytics_app_id: 'f085468f71664440ba6153ccb1036a9a',
  //aws_mobile_analytics_app_region: 'us-east-1',
  //aws_project_id: '5b55d3ef-e764-4657-b5c6-c0b023e8790e',
  //aws_project_name: 'EnhanzedRN',
  aws_project_region: "ap-south-1",
  //aws_push_apns: 'enable',
  //aws_push_pinpoint: 'enable',
  aws_resource_name_prefix: "usp-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "ap-south-1_8xUeK3rFi",
  aws_user_pools_web_client_id: "1ocpjbt5jfb937b6l6pclmb16",
  aws_cloud_logic_custom_endpoint:
    "https://j6k3abvofd.execute-api.ap-south-1.amazonaws.com/",
  aws_org_id: "USP",
  aws_org_id_E: "USP",
  aws_user_pools_id_E: "ap-south-1_8xUeK3rFi",
  aws_user_pools_web_client_id_E: "1ocpjbt5jfb937b6l6pclmb16",
  //aws_mobile_analytics_app_id_E: 'f085468f71664440ba6153ccb1036a9a',
  aws_content_delivery_cloudfront_domain_E: "d1gr06hnj0sjrr.cloudfront.net",
  aws_cloudfront_url: "www.usp.enhanzed.com",
  aws_cognito_identity_pool_id_E:
    "us-east-1:f6c1c940-5cc0-4779-9813-d1f4ca08f60a",
  aws_cloud_logic_custom_endpoint_E:
    "https://j6k3abvofd.execute-api.ap-south-1.amazonaws.com/USP01prod",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "j6k3abvofd",
      name: "USP-PLATFORM",
      description: "",
      endpoint:
        "https://j6k3abvofd.execute-api.ap-south-1.amazonaws.com/USP01prod",
      region: "ap-south-1",
    },
  ],
};

export default uspprod;
